<template>
  <div>
    <b-card>
      <b-tabs vertical nav-wrapper-class="nav-vertical">
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Employee Information</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6" class="mb-2">
                <h5 class="mb-0">Account Details</h5>
                <small class="text-muted"> Employee Account Details. </small>
              </b-col>
              <b-col md="6">
                <div class="demo-inline-spacing">
                  <b-form-checkbox v-model="data_local.status" switch inline>
                    Status
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col sm="12">
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="data_local.avatar"
                        height="80"
                      />
                    </b-link>
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <div
                      v-show="imageSrc"
                      class="my-2 w-64 h-64 object-fill mx-auto"
                      style="
                        object-fit: fill;
                        width: 16rem;
                        height: 16rem;
                        margin-left: auto;
                        margin-right: auto;
                      "
                    >
                      <img
                        style="display: block; max-width: 100%"
                        class="block max-w-full"
                        ref="img"
                        :src="imageSrc"
                      />
                      <button
                        class="btn btn-gray text-danger close-crop-btn"
                        @click.stop.prevent="fileCleared"
                      >
                        <feather-icon icon="XIcon" size="22" />
                      </button>
                    </div>

                    <div class="btn-crop-container mt-2">
                      <b-button
                        v-if="!imageSrc"
                        class="btn btn-blue w-32 mx-2"
                        variant="primary"
                        @click.stop.prevent="imageInput.click()"
                      >
                        Select Image
                      </b-button>
                      <b-button
                        v-if="imageSrc && !cropped"
                        class="btn btn-gray w-32 mx-2"
                        variant="primary"
                        @click.stop.prevent="handleCropImage"
                      >
                        Crop
                      </b-button>

                      <input
                        type="file"
                        ref="imageInput"
                        accept=".jpg,jpeg,.png"
                        @change="fileChanged"
                        :style="{ display: 'none' }"
                      />

                      <b-button
                        class="btn btn-gray w-32 mx-2"
                        variant="outline-primary"
                        @click.stop.prevent="removeProfileAvatar"
                      >
                        Remove
                      </b-button>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  @click="updateProfilePic"
                >
                Set Profile Photo 
                </b-button>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="employee_type">Title</label>
                  <v-select
                    v-model="data_local.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="title_options"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="title"
                    placeholder="Select Employee Title.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Name" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="data_local.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Employee Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nickname" label-for="nick-name">
                  <validation-provider #default="{ errors }" name="nickname">
                    <b-form-input
                      id="nick-name"
                      v-model="data_local.nickname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Employee Nickname"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="data_local.email"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="employee@mail.com"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="d-flex align-items-center"
                    >Password
                    <feather-icon
                      icon="AlertCircleIcon"
                      class="mr-25 ml-25 text-primary"
                      v-b-tooltip.hover.v-default
                      title="This password will be used by the employee to log in. (Leave
                  blank if you want to keep your current password)"
                    />
                  </label>
                  <PasswordInput :value.sync="data_local.password" />
                  <small> </small>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Gender" label-for="last-name">
                  <div class="demo-inline-spacing">
                    <b-form-radio v-model="data_local.gender" value="male">
                      Male
                    </b-form-radio>
                    <b-form-radio v-model="data_local.gender" value="female">
                      Female
                    </b-form-radio>
                    <b-form-radio v-model="data_local.gender" value="other">
                      Other
                    </b-form-radio>
                  </div>
                </b-form-group></b-col
              >
              <b-col md="6">
                <b-form-group label="Mobile" label-for="mobile">
                  <validation-provider
                    #default="{ errors }"
                    name="mobile"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="data_local.mobile"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Mobile Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Extention" label-for="extention">
                  <validation-provider #default="{ errors }" name="extention">
                    <b-form-input
                      id="extention"
                      v-model="data_local.extention"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Extention"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Employee Id" label-for="employee id">
                  <validation-provider
                    #default="{ errors }"
                    name="employee id"
                    rules="required"
                  >
                    <b-form-input
                      id="employee-id"
                      v-model="data_local.employee_id"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Employee Id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Confirmation Date"
                  label-for="confirmation-date"
                >
                  <flat-pickr
                    v-model="data_local.confirmation_date"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="Confirmation Date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                >
                <label class="required-l" for="date of joining"
                  >Date Of Joining</label
                >
                  <flat-pickr
                    v-model="data_local.date_of_join"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="Date of Join"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Probation Period (Days)"
                  label-for="probation period"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="probation period"
                    rules="numeric"
                  >
                    <b-form-input
                      id="probation-period"
                      v-model="data_local.probation_days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Probation Period in Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="employee_type"
                    >Employee Type</label
                  >
                  <v-select
                    v-model="data_local.employee_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="list_employee_types"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="employee_type"
                    placeholder="Select Employee Type.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Personal Information</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group label="Date of Birth" label-for="date-of-birth">
                  <flat-pickr
                    v-model="data_local.date_of_birth"
                    :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                    class="form-control"
                    name="Date of Birth"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="blood-group"
                    >Blood Group</label
                  >
                  <v-select
                    v-model="data_local.blood_group"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="blood_group_options"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="blood-group"
                    placeholder="Select Blood Group.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Father Name" label-for="father-name">
                  <b-form-input
                    id="father-name"
                    v-model="data_local.father_name"
                    placeholder="Father Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="marital-status"
                    >Marital Status</label
                  >
                  <v-select
                    v-model="data_local.marital_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="merital_status_options"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="marital-status"
                    placeholder="Select Marital Status.."
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                v-if="
                  data_local.marital_status &&
                  data_local.marital_status == 'married'
                "
              >
                <b-form-group
                  label="Date Of Marriage"
                  label-for="date-of-marriage"
                >
                  <flat-pickr
                    v-model="data_local.date_of_marriage"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="Date of Marriage"
                    placeholder="Select  Marriage Date.."
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Spouse Name" label-for="spouse-name">
                  <b-form-input
                    id="spouse-name"
                    v-model="data_local.spouse_name"
                    type="text"
                    placeholder="Spouse Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Place of Birth" label-for="birth-place">
                  <b-form-input
                    id="birth-place"
                    v-model="data_local.place_of_birth"
                    type="text"
                    placeholder="Birth Place"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Country of Origin"
                  label-for="origin-county"
                >
                  <b-form-input
                    id="origin-country"
                    v-model="data_local.orgin_country"
                    type="text"
                    placeholder="Origin Country"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Religion" label-for="religion">
                  <b-form-input
                    id="religion"
                    v-model="data_local.religion"
                    type="text"
                    placeholder="Religion"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Is International Employee"
                  label-for="is-international-employee"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.is_international_employee"
                      value="yes"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.is_international_employee"
                      value="no"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Is Physically Challenged"
                  label-for="Is-Physically-Challenged"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.is_physically_challenged"
                      value="yes"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.is_physically_challenged"
                      value="no"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Personal Email" label-for="personal-email">
                  <validation-provider
                    #default="{ errors }"
                    name="personal-email"
                  >
                    <b-form-input
                      id="personal-email"
                      v-model="data_local.personal_email"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="employee@gmail.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Joining Details</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Confirmation Date"
                  label-for="confirmation-date"
                >
                  <flat-pickr
                    v-model="data_local.confirmation_date"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="Confirmation Date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Date Of Joining"
                  label-for="date-of-joining"
                >
                  <flat-pickr
                    v-model="data_local.date_of_join"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="Date of Join"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Probation Period (Days)"
                  label-for="probation period"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="probation period"
                    rules="numeric"
                  >
                    <b-form-input
                      id="probation-period"
                      v-model="data_local.probation_days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Probation Period in Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Previous Company Experience (Years)"
                  label-for="previous-company-experience"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="previous-company-experience"
                    rules="numeric"
                  >
                    <b-form-input
                      id="previous-company-experience"
                      v-model="data_local.previous_company_experience"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Previous Company Experience (Years)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Notice Period (Days)"
                  label-for="notice-period"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="notice period"
                    rules="numeric"
                  >
                    <b-form-input
                      id="notice-period"
                      v-model="data_local.notice_period_days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Notice Period in Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Current Position</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="roles">Roles</label>
                  <v-select
                    v-model="data_local.role_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="list_roles"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="role"
                    placeholder="Select Role.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <label class="mr-sm-2 required-l" for="designation"
                  >Designation
                  <b-button
                    v-b-modal.designation-modal
                    size="sm"
                    variant="outline-light"
                    class="border-0 p-0"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="16"
                      class="text-warning"
                  /></b-button>
                </label>
                <v-select
                  v-model="data_local.designation_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="designationOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="designation"
                  value.sync="designation_id"
                  placeholder="Select Designation.."
                />
              </b-col>
              <b-col md="6">
                <label class="mr-sm-2 required-l" for="division"
                  >Division
                  <b-button
                    v-b-modal.division-modal
                    size="sm"
                    variant="outline-light"
                    class="border-0 p-0"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="16"
                      class="text-warning"
                  /></b-button>
                </label>
                <v-select
                  v-model="data_local.division_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="divisionOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="division"
                  value.sync="division_id"
                  placeholder="Select Division.."
                />
              </b-col>
              <b-col md="6">
                <label class="mr-sm-2 required-l" for="department"
                  >Department
                  <b-button
                    v-b-modal.department-modal
                    size="sm"
                    variant="outline-light"
                    class="border-0 p-0"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="16"
                      class="text-warning"
                  /></b-button>
                </label>
                <v-select
                  v-model="data_local.department_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departmentOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="department"
                  value.sync="department_id"
                  placeholder="Select Department.."
                />
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Reporting Manager"
                  label-for="reporting manager"
                >
                  <v-select
                    v-model="data_local.reporting_manager"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="reportingManagerOptions"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="true"
                    input-id="reporting-manager"
                    placeholder="Select Employee.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <label class="mr-sm-2 required-l" for="Communication Channel ID"
                  >Communication Channel Type
                </label>
                <v-select
                  v-model="data_local.channel_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="channelOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="channel_type"
                  value.sync="channel_type"
                  placeholder="Select Communication Channel.."
                />
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Communication Channel ID"
                  label-for="communication-channel"
                >
                  <b-form-input
                    id="communication-channel"
                    v-model="data_local.channel_id"
                    type="text"
                    placeholder="Emergency Communication Channel ID"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="shift">Shifts</label>
                  <v-select
                    v-model="data_local.shift_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="list_shifts"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="true"
                    input-id="shift"
                    value.sync="shift"
                    placeholder="Select Shift.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Location" label-for="location">
                  <b-form-input
                    id="location"
                    v-model="data_local.branch_location"
                    type="text"
                    placeholder="Location/Branch"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Work From Home Location" label-for="wfh">
                  <b-form-input
                    id="wfh"
                    v-model="data_local.wfh_location"
                    type="text"
                    placeholder="Work From Home Location"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Employee Identity</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <label class="mr-sm-2 required-l" for="Identity Type"
                  >Identity Type
                </label>
                <v-select
                  v-model="data_local.identity_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="IDOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="identity_type"
                  value.sync="identity_type"
                  placeholder="Select Identity Type.."
                />
              </b-col>
              <b-col
                md="6"
                v-if="
                  data_local.identity_type &&
                  data_local.identity_type == 'aadhaar'
                "
              >
                <b-form-group label="Aadhaar Number" label-for="aadhaar">
                  <b-form-input
                    id="aadhaar"
                    v-model="data_local.aadhaar"
                    type="text"
                    placeholder="Enter Aadhaar Number "
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                v-if="
                  data_local.identity_type &&
                  data_local.identity_type == 'pan_number'
                "
              >
                <b-form-group label="PAN Number" label-for="pan_number">
                  <b-form-input
                    id="pan_number"
                    v-model="data_local.pan_number"
                    type="text"
                    placeholder="Enter PAN Number "
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                v-if="
                  data_local.identity_type &&
                  data_local.identity_type == 'passport'
                "
              >
                <b-form-group label="Passport" label-for="passport">
                  <b-form-input
                    id="passport"
                    v-model="data_local.passport"
                    type="text"
                    placeholder="Enter Passport Number "
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                v-if="
                  data_local.identity_type &&
                  data_local.identity_type == 'social_security_number'
                "
              >
                <b-form-group
                  label="Social Security Number"
                  label-for="social_security_number"
                >
                  <b-form-input
                    id="social_security_number"
                    v-model="data_local.social_security_number"
                    type="text"
                    placeholder="Enter Social Security Number "
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Address</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Address Info</h5>
                <small class="text-muted">Enter Employee Address Info.</small>
              </b-col>
              <h5 class="col-12 d-flex justify-content-between">
                <div>Current Address</div>
              </h5>
              <b-col md="6">
                <b-form-group label="Address Line 1" label-for="address-line-1">
                  <b-form-input
                    id="address-line-1"
                    v-model="data_local.location.add_line_1"
                    type="text"
                    placeholder="Address Line 1"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Address Line 2" label-for="address-line-2">
                  <b-form-input
                    id="address-line-2"
                    v-model="data_local.location.add_line_2"
                    type="text"
                    placeholder="Address Line 2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Postcode" label-for="postcode">
                  <b-form-input
                    id="postcode"
                    v-model="data_local.location.post_code"
                    type="text"
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="City" label-for="city">
                  <b-form-input
                    id="city"
                    v-model="data_local.location.city"
                    type="text"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="State" label-for="state">
                  <b-form-input
                    id="state"
                    v-model="data_local.location.state"
                    type="text"
                    placeholder="State"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Country" label-for="country">
                  <b-form-input
                    id="country"
                    v-model="data_local.location.country"
                    type="text"
                    placeholder="Country"
                  />
                </b-form-group>
              </b-col>
              <h5 class="col-12 d-flex justify-content-between">
                <div>Permanent Address</div>
                <div>
                  Same as Current Address
                  <b-button
                    type="button"
                    variant="primary"
                    size="sm"
                    @click="copyLocation"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </div>
              </h5>
              <b-col md="6">
                <b-form-group
                  label="Address Line 1"
                  label-for="permanent-address-address-line-1"
                >
                  <b-form-input
                    id="permanent-address-address-line-1"
                    v-model="permanent_address.add_line_1"
                    type="text"
                    placeholder="Address Line 1"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Address Line 2"
                  label-for="permanent-address-address-line-2"
                >
                  <b-form-input
                    id="permanent-address-address-line-2"
                    v-model="permanent_address.add_line_2"
                    type="text"
                    placeholder="Address Line 2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Postcode"
                  label-for="permanent-address-postcode"
                >
                  <b-form-input
                    id="permanent-address-postcode"
                    v-model="permanent_address.post_code"
                    type="text"
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="City" label-for="permanent-address-city">
                  <b-form-input
                    id="permanent-address-city"
                    v-model="permanent_address.city"
                    type="text"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="State" label-for="permanent-address-state">
                  <b-form-input
                    id="permanent-address-state"
                    v-model="permanent_address.state"
                    type="text"
                    placeholder="State"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Country"
                  label-for="permanent-address-country"
                >
                  <b-form-input
                    id="permanent-address-country"
                    v-model="permanent_address.country"
                    type="text"
                    placeholder="Country"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Checklist</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col class="md-8" />
              <b-col class="md-4">
                <b-form autocomplete="off" class="faq-search-input">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="searchbar"
                      v-model="faqSearchQuery"
                      placeholder="Search Checklist..."
                    />
                  </b-input-group>
                </b-form>
              </b-col>
            </b-row>

            <section id="faq-tabs" class="mt-2 mb-2">
              <div v-if="faqData.length">
                <b-tabs
                  vertical
                  content-class="col-12 col-md-8 col-lg-9"
                  pills
                  nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
                  nav-class="nav-left"
                >
                  <b-tab
                    v-for="(obj, index) in faqData"
                    :key="index"
                    :active="!index"
                  >
                    <!-- title -->
                    <template #title>
                      <span class="font-weight-bold">{{ obj.title }}</span>
                    </template>

                    <div v-if="obj">
                      <div class="d-flex align-items-center mt-1 mb-2">
                        <div>
                          <h4 class="mb-0">
                            {{ obj.title }}
                          </h4>
                        </div>
                      </div>
                      <b-list-group>
                        <b-list-group-item
                          v-for="(data, indexes) in obj.descriptions"
                          :key="indexes"
                        >
                          <b-form-checkbox
                            v-model="data_local.checklist_description_ids"
                            :value="data.hashid"
                            >{{ data.description }}</b-form-checkbox
                          ></b-list-group-item
                        >
                      </b-list-group>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <div v-else class="text-center">
                <span>No Checklist Found</span>
              </div>
            </section>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Emergency Contact</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Emergency Contact Name"
                  label-for="emergency-contact-name"
                >
                  <b-form-input
                    id="emergency-contact-name"
                    v-model="data_local.emergency_contact_name"
                    type="text"
                    placeholder="Emergency Contact Name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Emergency Contact Number"
                  label-for="emergency-contact-number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="emergency contact number"
                    rules="numeric"
                  >
                    <b-form-input
                      id="emergency-contact-number"
                      v-model="data_local.emergency_contact_number"
                      type="text"
                      placeholder="Emergency Contact Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Relationship" label-for="relationship">
                  <b-form-input
                    id="relationship"
                    v-model="data_local.relationship"
                    type="text"
                    placeholder="Relationship"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Address Line 1"
                  label-for="emergency-contact-address-address-line-1"
                >
                  <b-form-input
                    id="emergency-contact-address-address-line-1"
                    v-model="emergency_contact_address.add_line_1"
                    type="text"
                    placeholder="Address Line 1"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Address Line 2"
                  label-for="emergency-contact-address-address-line-2"
                >
                  <b-form-input
                    id="emergency-contact-address-address-line-2"
                    v-model="emergency_contact_address.add_line_2"
                    type="text"
                    placeholder="Address Line 2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Postcode"
                  label-for="emergency-contact-address-postcode"
                >
                  <b-form-input
                    id="emergency-contact-address-postcode"
                    v-model="emergency_contact_address.post_code"
                    type="text"
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="City"
                  label-for="emergency-contact-address-city"
                >
                  <b-form-input
                    id="emergency-contact-address-city"
                    v-model="emergency_contact_address.city"
                    type="text"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="State"
                  label-for="emergency-contact-address-state"
                >
                  <b-form-input
                    id="emergency-contact-address-state"
                    v-model="emergency_contact_address.state"
                    type="text"
                    placeholder="State"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Country"
                  label-for="emergency-contact-address-country"
                >
                  <b-form-input
                    id="emergency-contact-address-country"
                    v-model="emergency_contact_address.country"
                    type="text"
                    placeholder="Country"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Background Check</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Verification Status"
                  label-for="verification_status"
                >
                  <b-form-input
                    id="verification_status"
                    v-model="background_check.verification_status"
                    type="text"
                    placeholder="Enter Verification Status"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agency Name" label-for="agency_name">
                  <b-form-input
                    id="agency_name"
                    v-model="background_check.agency_name"
                    type="text"
                    placeholder="Enter Agency Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <label for="remarks">Remarks</label>
                <b-form-textarea
                  id="remarks"
                  placeholder="Enter Remarks"
                  v-model="background_check.remarks"
                  rows="3"
                />
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Verified on Date"
                  label-for="verified-date"
                >
                  <flat-pickr
                    v-model="background_check.verified_on"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="verified-date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Remarks</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <label for="remarks">Employee Remarks</label>
                <b-form-textarea
                  id="remarks"
                  placeholder="Enter Remarks"
                  v-model="data_local.remarks"
                  rows="3"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Position History</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-button
                    class="mt-2"
                    style="width: 50%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addPositionHistory()"
                  >
                    Add Position History
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div
                  style="
                    border: 2px solid #38b22d;
                    padding: 15px;
                    border-radius: 50px 20px;
                  "
                  class="mt-1"
                  v-for="(history, k) in position_history"
                  :key="k"
                >
                  <b-row>
                    <b-col md="6">
                      <label class="mr-sm-2 required-l" for="designation"
                        >Designation
                        <b-button
                          v-b-modal.designation-modal
                          size="sm"
                          variant="outline-light"
                          class="border-0 p-0"
                        >
                          <feather-icon
                            icon="PlusCircleIcon"
                            size="16"
                            class="text-warning"
                        /></b-button>
                      </label>
                      <v-select
                        v-model="history.designation_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="designationOptions"
                        :reduce="(label) => label.code"
                        label="label"
                        :clearable="false"
                        :searchable="true"
                        input-id="designation"
                        value.sync="history.designation"
                        placeholder="Select Designation.."
                      />
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Effective From Date"
                        label-for="effective-from-date"
                      >
                        <flat-pickr
                          v-model="history.effective_from"
                          :config="{ dateFormat: 'Y-m-d' }"
                          class="form-control"
                          name="effective-from-date"
                          placeholder="Select Date.."
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Effective To Date"
                        label-for="effective-to-date"
                      >
                        <flat-pickr
                          v-model="history.effective_to"
                          :config="{ dateFormat: 'Y-m-d' }"
                          class="form-control"
                          name="effective-to-date"
                          placeholder="Select Date.."
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <b-button
                          class="mt-2"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          @click="remove(k)"
                          v-if="position_history.length > 1"
                        >
                          Delete
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Previous Employments</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-button
                    class="mt-2"
                    style="width: 50%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addPreviousEmployment()"
                  >
                    Add Previous Employment
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div
                  style="
                    border: 2px solid #38b22d;
                    padding: 15px;
                    border-radius: 50px 20px;
                  "
                  class="mt-1"
                  v-for="(previous_employeement, k) in previous_employeements"
                  :key="k"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Company Name"
                        label-for="company-name"
                      >
                        <b-form-input
                          id="company-name"
                          v-model="previous_employeement.company_name"
                          type="text"
                          placeholder="Enter Company Name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <label class="mr-sm-2 required-l" for="designation"
                        >Designation
                        <b-button
                          v-b-modal.designation-modal
                          size="sm"
                          variant="outline-light"
                          class="border-0 p-0"
                        >
                          <feather-icon
                            icon="PlusCircleIcon"
                            size="16"
                            class="text-warning"
                        /></b-button>
                      </label>
                      <v-select
                        v-model="previous_employeement.designation_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="designationOptions"
                        :reduce="(label) => label.code"
                        label="label"
                        :clearable="false"
                        :searchable="true"
                        input-id="designation"
                        value.sync="previous_employeement.designation"
                        placeholder="Select Designation.."
                      />
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Effective From Date"
                        label-for="effective-from-date"
                      >
                        <flat-pickr
                          v-model="previous_employeement.effective_from"
                          :config="{ dateFormat: 'Y-m-d' }"
                          class="form-control"
                          name="effective-from-date"
                          placeholder="Select Date.."
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Effective To Date"
                        label-for="effective-to-date"
                      >
                        <flat-pickr
                          v-model="previous_employeement.effective_to"
                          :config="{ dateFormat: 'Y-m-d' }"
                          class="form-control"
                          name="effective-to-date"
                          placeholder="Select Date.."
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Company Experience (Years)"
                        label-for="company-experience"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="company-experience"
                          rules="numeric"
                        >
                          <b-form-input
                            id="company-experience"
                            v-model="previous_employeement.experience"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Previous Company Experience (Years)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="PF Number" label-for="pf-number">
                        <b-form-input
                          id="pf-number"
                          v-model="previous_employeement.pf_number"
                          type="text"
                          placeholder="Enter PF Number"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <label for="company-address">Company Address</label>
                      <b-form-textarea
                        id="company-address"
                        placeholder="Enter Company Address"
                        v-model="previous_employeement.company_address"
                        rows="3"
                      />
                    </b-col>
                    <b-col md="6">
                      <label for="leaving-reason">Leaving Reason</label>
                      <b-form-textarea
                        id="leaving-reason"
                        placeholder="Enter Leaving Reason"
                        v-model="previous_employeement.leaving_reason"
                        rows="3"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <b-button
                          class="mt-2"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          @click="removePreviousEmployeement(k)"
                          v-if="previous_employeements.length > 1"
                        >
                          Delete
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Documents</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-button
                    class="mt-2"
                    style="width: 50%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-toggle.sidebar-add-document
                    id="add-document"
                  >
                    Add Documents
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div
                  style="
                    border: 2px solid #38b22d;
                    padding: 15px;
                    border-radius: 50px 20px;
                  "
                  class="mt-1"
                  v-for="(document, k) in documents"
                  :key="k"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Document Name"
                        label-for="document-name"
                      >
                        <b-form-input
                          id="document-name"
                          v-model="document.document_name"
                          type="text"
                          disabled
                          placeholder="Enter Document Name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <label for="description">Description</label>
                      <b-form-textarea
                        id="description"
                        placeholder="Enter Description"
                        v-model="document.description"
                        disabled
                        rows="3"
                      />
                    </b-col>
                    <b-col md="6">
                      <!-- <b-form-group
                  v-if="!document.file"
                  label="Upload Document"
                  label-for="files"
                >
                  <file-pond
                    ref="pond"
                    name="files[]"
                    max-file-size="5MB"
                    max-files="1"
                    allow-multiple="false"
                    image-preview-height="120px"
                  />
                  <small class="text-warning flex items-center">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be less than 5MB.
                  </small>
                </b-form-group> -->

                      <div>
                        Uploaded Document
                        <feather-icon
                          v-b-tooltip.hover.top="'View Document'"
                          class="btn-link cursor-pointer ml-1"
                          icon="EyeIcon"
                          @click.stop.prevent="openWindow(document.file)"
                        />
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <b-row class="mt-2">
                        <b-col cols="6">
                          <b-button
                            class="mt-2"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="editDocument(k)"
                          >
                            Edit
                          </b-button>
                        </b-col>
                        <b-col cols="6">
                          <b-button
                            class="mt-2"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            @click="removeDocument(k)"
                          >
                            Delete
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Resignation Details</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Submitted on Date"
                  label-for="submitted-date"
                >
                  <flat-pickr
                    v-model="resignation.submitted_on"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="submitted-date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                  class="mt-2"
                  v-model="resignation.is_notice_required"
                  value="yes"
                >
                  Notice Required
                </b-form-checkbox>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Notice Period (Days)"
                  label-for="notice-period"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="notice period"
                    rules="numeric"
                  >
                    <b-form-input
                      id="notice-period"
                      v-model="resignation.notice_period_days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Notice Period in Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Short Fall in Notice Period (Days)"
                  label-for="short-fall-notice-period"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="shoart notice period"
                    rules="numeric"
                  >
                    <b-form-input
                      id="short notice-period"
                      v-model="resignation.short_fall_notice_period_days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Short Fall in Notice Period in Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Tentative Leaving Date"
                  label-for="tentative-date"
                >
                  <flat-pickr
                    v-model="resignation.tentative_date"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="tentative-date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                  class="mt-2"
                  v-model="resignation.is_exlude_from_final_settlement"
                  value="yes"
                >
                  Exlude From Final Settlement
                </b-form-checkbox>
              </b-col>
              <b-col md="6">
                <label for="leaving-reason">Leaving Reason</label>
                <b-form-textarea
                  id="leaving-reason"
                  placeholder="Enter Leaving Reason"
                  v-model="resignation.leaving_reason"
                  rows="3"
                />
              </b-col>
              <b-col md="6">
                <label for="Remarks">Remarks</label>
                <b-form-textarea
                  id="remarks"
                  placeholder="Enter Remarks"
                  v-model="resignation.remarks"
                  rows="3"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>

        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Exit Details</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group label="Leaving Date" label-for="leaving-date">
                  <flat-pickr
                    v-model="exit_details.leaving"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="submitted-date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Settled Date" label-for="settled-date">
                  <flat-pickr
                    v-model="exit_details.settled_on"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="settled-date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                  class="mt-2"
                  v-model="exit_details.notice_served"
                  value="yes"
                >
                  Notice Served
                </b-form-checkbox>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                  class="mt-2"
                  v-model="exit_details.has_left_the_company"
                  value="yes"
                >
                  Has left the company
                </b-form-checkbox>
              </b-col>

              <b-col md="6">
                <b-form-checkbox
                  class="mt-2"
                  v-model="exit_details.fit_to_be_rehired"
                  value="yes"
                >
                  Fit to be ReHired.
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="margin-right: auto">Exit Interview</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group label="Interview Date" label-for="interview-date">
                  <flat-pickr
                    v-model="data_local.exit_interview_date"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control"
                    name="interview-date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label for="remarks">Remarks</label>
                <b-form-textarea
                  id="remarks"
                  placeholder="Enter Remarks"
                  v-model="data_local.exit_interview_remarks"
                  rows="3"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 ml-auto mr-1"
                @click="formSubmitted(true)"
              >
                Update
              </b-button>
            </b-row>
          </validation-observer>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-sidebar
      id="sidebar-add-document"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      width="50%"
      right
      no-close-on-backdrop
      @shown="resetFormData"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Add Document</h5>
          <div>
            <b-button
              variant="outline-primary"
              class="mr-2 min-w-100 px-3"
              id="document-cancel"
              @click="hide"
              pill
            >
              Cancel
            </b-button>
            <b-button
              pill
              variant="primary"
              @click="documentSubmitted()"
              class="px-3"
            >
              Submit
            </b-button>
          </div>
        </div>
        <!-- BODY -->
        <validation-observer
          ref="DocumentForm"
          tag="form"
          style="overflow: hidden"
        >
          <b-form autocomplete="off" id="document-form" class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="document_name"
                    rules="required"
                  >
                    <label for="files" class="required-l">Document Name</label>
                    <b-form-input
                      id="document-name"
                      v-model="document.document_name"
                      type="text"
                      placeholder="Enter Document Name"
                      required
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label for="description">Description</label>
                <b-form-textarea
                  id="description"
                  placeholder="Enter Description"
                  v-model="document.description"
                  rows="3"
                />
              </b-col>
              <b-col md="12">
                <label for="files" class="required-l">Document</label>
                <b-form-group v-if="document.file == null" class="required-l">
                  <file-pond
                    key="myFiles"
                    ref="pond"
                    name="files[]"
                    max-file-size="5MB"
                    max-files="1"
                    v-bind:files="myFiles"
                    allow-multiple="false"
                    image-preview-height="120px"
                    required
                  />
                  <small class="text-warning flex items-center">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be less than 5MB.
                  </small>
                </b-form-group>

                <div v-else>
                  Uploaded Document
                  <feather-icon
                    v-b-tooltip.hover.top="'View Document'"
                    class="btn-link cursor-pointer ml-1"
                    icon="EyeIcon"
                    @click.stop.prevent="openWindow(document.file)"
                  />
                  <feather-icon
                    v-b-tooltip.hover.top="'Remove Document'"
                    class="btn-link cursor-pointer ml-1"
                    icon="TrashIcon"
                    @click.stop.prevent="document.file = null"
                  />
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal
      id="designation-modal"
      ref="modal"
      size="md"
      title="Submit Your Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDesignation"
    >
      <template #modal-header="{ close }">
        <h5>Add Designation</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <!-- <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col cols="2"> # </b-col>
            <b-col cols="4"> Designation </b-col>
            <b-col cols="6" class="text-center"> Action </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          v-for="(item, index) in designationOptions"
          :key="item.id"
        >
          <b-row>
            <b-col cols="2">
              {{ ++index }}
            </b-col>
            <b-col cols="4">
              {{ item.label }}
            </b-col>
            <b-col cols="6" class="text-center">
              <b-button
                variant="danger"
                size="sm"
                @click="removeSource(item)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="text-nowrap">Remove</span>
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group> -->
      <form ref="form" @submit.stop.prevent="addDesignation" class="mt-1">
        <b-form-group
          label="Designation Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="designationNameState"
        >
          <b-form-input
            id="name-input"
            v-model="designationName"
            :state="designationNameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="division-modal"
      ref="modal"
      size="md"
      title="Submit Division Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDivision"
    >
      <template #modal-header="{ close }">
        <h5>Add Division</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <form ref="form" @submit.stop.prevent="addDivision" class="mt-1">
        <b-form-group
          label="Division Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="divisionNameState"
        >
          <b-form-input
            id="name-input"
            v-model="divisionName"
            :state="divisionNameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="department-modal"
      ref="modal"
      size="md"
      title="Submit Department Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDepartment"
    >
      <template #modal-header="{ close }">
        <h5>Add Department</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <form ref="form" @submit.stop.prevent="addDepartment" class="mt-1">
        <b-form-group
          label="Department Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="departmentNameState"
        >
          <b-form-input
            id="name-input"
            v-model="departmentName"
            :state="departmentNameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import {
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  watch,
} from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";
import employeeStoreModule from "../employeeStoreModule";
import PasswordInput from "@/components/PasswordInput.vue";
import Ripple from "vue-ripple-directive";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import contractStoreModule from "@/views/pages/contractStoreModule";
import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    VBTooltip,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    PasswordInput,
    FilePond,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      email,
      numeric,
      faqSearchQuery: "",
      faqData: {},
      reportingManagerOptions: [],
      data_local: {
        name: "",
        email: "",
        status: true,
        avatar: "",
        employee_id: "",
        reporting_manager: "",
        date_of_join: "",
        probation_days: "",
        confirmation_date: "",
        date_of_birth: "",
        mobile: "",
        gender: "",
        emergency_contact_name: "",
        emergency_contact_number: "",
        father_name: "",
        spouse_name: "",
        designation_id: "",
        location: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "India",
        },
        employee_type: "",
        permanent_address: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "",
        },
        background_check: {
          verification_status: "",
          agency_name: "",
          remarks: "",
          verified_on: "",
        },
      },
      list_roles: [],
      designationOptions: [],
      divisionOptions: [],
      departmentOptions: [],
      myFiles: [],
      designationName: "",
      designationNameState: null,
      divisionName: null,
      divisionNameState: null,
      departmentName: null,
      departmentNameState: null,
      list_shifts: [],
      list_employee_types: [
        { label: "Permanent", code: "permanent" },
        { label: "Contract", code: "contract" },
      ],
      title_options: [
        { label: "Mr.", code: "mr" },
        { label: "Ms.", code: "ms" },
        { label: "Mrs.", code: "mrs" },
        { label: "Dr.", code: "dr" },
        { label: "Prof.", code: "prof" },
      ],
      blood_group_options: [
        { label: "A RhD positive (A+)", code: "A+" },
        { label: "A RhD negative (A-)", code: "A-" },
        { label: "B RhD positive (B+)", code: "B+" },
        { label: "B RhD negative (B-)", code: "B-" },
        { label: "O RhD positive (O+)", code: "O+" },
        { label: "O RhD negative (O-)", code: "O-" },
        { label: "AB RhD positive (AB+)", code: "AB+" },
        { label: "AB RhD negative (AB-)", code: "AB-" },
      ],
      merital_status_options: [
        { label: "Married", code: "married" },
        { label: "Single", code: "single" },
        { label: "Separated", code: "separated" },
        { label: "Widowed", code: "widowed" },
      ],
      channelOptions: [
        { label: "Slack", code: "slack" },
        { label: "Skype", code: "skype" },
        { label: "Microsoft Teams", code: "teams" },
        { label: "Email", code: "email" },
      ],
      IDOptions: [
        { label: "Aadhaar Card", code: "aadhaar" },
        { label: "Passport", code: "passport" },
        { label: "Social Security Number", code: "social_security_number" },
        { label: "PAN", code: "pan_number" },
      ],
      permanent_address: {
        add_line_1: "",
        add_line_2: "",
        post_code: "",
        city: "",
        state: "",
        country: "",
      },
      emergency_contact_address: {
        add_line_1: "",
        add_line_2: "",
        post_code: "",
        city: "",
        state: "",
        country: "",
      },
      background_check: {
        verification_status: "",
        agency_name: "",
        remarks: "",
        verified_on: "",
      },
      position_history: [
        {
          designation_id: "",
          designation: "",
          effective_from: "",
          effective_to: "",
        },
      ],
      previous_employeements: [
        {
          designation_id: "",
          designation: "",
          effective_from: "",
          effective_to: "",
          company_name: "",
          experience: 0,
          leaving_reason: "",
          company_address: "",
          pf_number: "",
        },
      ],
      documents: [],
      resignation: {
        is_notice_required: "",
        submitted_on: "",
        leaving_reason: "",
        remarks: "",
        tentative_date: "",
        short_fall_notice_period_days: "",
        is_exlude_from_final_settlement: "",
        notice_period_days: "",
      },
      exit_details: {
        leaving_date: "",
        settled_on: "",
        notice_served: "",
        has_left_the_company: "",
        fit_to_be_rehired: "",
      },
      document: {
        document_name: "",
        description: "",
        file: null,
      },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-employees";
    const imageInput = ref(null);
    const selectedFile = ref(null);
    const imageSrc = ref(null);
    const img = ref(null);
    const cropped = ref(false);
    const fileReader = new FileReader();
    let cropper = null;

    fileReader.onload = (event) => {
      imageSrc.value = event.target.result;
    };

    const handleCropImage = () => {
      cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toBlob((blob) => {
          selectedFile.value = blob;
          cropped.value = true;
        }, "image/jpeg");
    };

    const fileChanged = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        selectedFile.value = files[0];
      }
    };

    const fileCleared = (_) => {
      selectedFile.value = null;
      cropped.value = false;
    };

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    onMounted(() => {
      cropper = new Cropper(img.value, {
        aspectRatio: 1,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        viewMode: 3,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
      });
    });

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      cropper.destroy();
    });
    watchEffect(() => {
      if (selectedFile.value) {
        fileReader.readAsDataURL(selectedFile.value);
      } else {
        imageSrc.value = null;
      }
    });

    watch(
      imageSrc,
      () => {
        if (imageSrc.value) {
          cropper.replace(imageSrc.value);
        }
      },
      {
        flush: "post",
      }
    );

    return {
      imageInput,
      selectedFile,
      fileChanged,
      fileCleared,
      imageSrc,
      img,
      handleCropImage,
      cropped,
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  created() {
    this.fetch_user_data(this.$route.params.id);
    this.fetchRolesList();
    this.fetchDesignationsList();
    this.fetchDivisionsList();
    this.fetchDepartmentsList();
    this.fetchShiftsList();
  },
  methods: {
    resetFormData() {
      console.log("reset");
      this.myFiles = [];
      this.document = [];
    },
    copyLocation() {
      this.permanent_address.add_line_1 = this.data_local.location.add_line_1;
      this.permanent_address.add_line_2 = this.data_local.location.add_line_2;
      this.permanent_address.city = this.data_local.location.city;
      this.permanent_address.state = this.data_local.location.state;
      this.permanent_address.country = this.data_local.location.country;
      this.permanent_address.post_code = this.data_local.location.post_code;
    },
    resetModal() {
      this.designationName = "";
    },
    addDesignation() {
      const self = this;
      let params = [];
      params.name = self.designationName;
      this.$store
        .dispatch("app-employees/addDesignation", params)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Designation Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Designation Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchDesignationsList();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Designation Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Designation Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    addDivision() {
      const self = this;
      let params = [];
      params.name = self.divisionName;
      this.$store
        .dispatch("app-employees/addDivision", params)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Division Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Division Added',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchDivisionsList();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Division Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Division Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    addDepartment() {
      const self = this;
      let params = [];
      params.name = self.departmentName;
      this.$store
        .dispatch("app-employees/addDepartment", params)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Department Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Department Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

           
          this.fetchDepartmentsList();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Department Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Department Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          
        });
    },
    removeDesignation(designation) {
      const self = this;
      let params = [];
      params.id = designation.id;
      this.$store
        .dispatch("app-employees/removeDesignation", params)
        .then((res) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Designation Removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Designation Removed',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchCandidateDesignation();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Designation Removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Designation Removing Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    removeDivision(division) {
      const self = this;
      let params = [];
      params.id = division.id;
      this.$store
        .dispatch("app-employees/removeDivision", params)
        .then((res) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Division Removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Division Removed',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info ",
              hideProgressBar : true,
            });
          this.fetchDivisionsList();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Division Removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

        });
    },
    removeDepartment(department) {
      const self = this;
      let params = [];
      params.id = department.id;
      this.$store
        .dispatch("app-employees/removeDepartment", params)
        .then((res) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Department Removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Department Removed',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

          this.fetchDepartmentsList();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Division Removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Division Removing Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchRolesList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchRolesList")
        .then((response) => {
          this.list_roles = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchShiftsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchShiftsList")
        .then((response) => {
          this.list_shifts = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchDesignationsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDesignationsList")
        .then((response) => {
          this.designationOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchDivisionsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDivisionList")
        .then((response) => {
          this.divisionOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchDepartmentsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDepartmentList")
        .then((response) => {
          this.departmentOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-employees/fetchEmployee", userId)
        .then((res) => {
          this.data_local = res.data.data;
          if (this.data_local.permanent_address) {
            this.permanent_address = this.data_local.permanent_address;
          }
          if (this.data_local.emergency_contact_address) {
            this.emergency_contact_address =
              this.data_local.emergency_contact_address;
          }
          if (this.data_local.background_check) {
            this.background_check = this.data_local.background_check;
          }
          if (this.data_local.position_history) {
            this.position_history = this.data_local.position_history;
          }
          if (this.data_local.previous_employeements) {
            this.previous_employeements =
              this.data_local.previous_employeements;
          }
          if (this.data_local.documents) {
            this.documents = this.data_local.documents;
          }
          if (this.data_local.resignation) {
            this.resignation = this.data_local.resignation;
          }
          if (this.data_local.exit_details) {
            this.exit_details = this.data_local.exit_details;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    formSubmitted($last_step = false) {
      const self = this;
      this.isloading = true;
      this.data_local._method = "PATCH";
      this.data_local.permanent_address = self.permanent_address;
      this.data_local.emergency_contact_address =
        self.emergency_contact_address;
      this.data_local.background_check = self.background_check;
      this.data_local.position_history = self.position_history;
      this.data_local.previous_employeements = self.previous_employeements;
      this.data_local.documents = self.documents;
      this.data_local.resignation = self.resignation;
      this.data_local.exit_details = self.exit_details;

      this.$store
        .dispatch("app-employees/updateEmployee", {
          data: this.data_local,
          userId: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Employee Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            // if ($last_step) {
            //   this.$router.push("/employees");
            // }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Employee updating Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Employee updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Employee Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Employee Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        })
        .finally(() => (this.isloading = false));
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.formSubmitted();
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.formSubmitted();
          } else {
            reject();
          }
        });
      });
    },
    fetchData() {
      this.$http
        .get("/checklistform", { params: { q: this.faqSearchQuery } })
        .then((res) => {
          this.faqData = res.data.data;
        });
      this.$http.get("/list/employees").then((res) => {
        this.reportingManagerOptions = res.data.data;
      });
    },
    remove(index) {
      this.position_history.splice(index, 1);
    },
    addPositionHistory() {
      if (!this.position_history) {
        this.position_history = [
          {
            designation_id: "",
            designation: "",
            effective_from: "",
            effective_to: "",
          },
        ];
      } else {
        this.position_history.push({
          designation_id: "",
          designation: "",
          effective_from: "",
          effective_to: "",
        });
      }
      console.log(this.position_history);
    },
    removePreviousEmployeement(index) {
      this.previous_employeements.splice(index, 1);
    },
    addPreviousEmployment() {
      if (!this.previous_employeements) {
        this.previous_employeements = [
          {
            designation_id: "",
            designation: "",
            effective_from: "",
            effective_to: "",
            company_name: "",
            experience: 0,
            leaving_reason: "",
            company_address: "",
            pf_number: "",
          },
        ];
      } else {
        this.previous_employeements.push({
          designation_id: "",
          designation: "",
          effective_from: "",
          effective_to: "",
          company_name: "",
          experience: 0,
          leaving_reason: "",
          company_address: "",
          pf_number: "",
        });
      }
      console.log(this.previous_employeements);
    },
    removeDocument(index) {
      this.documents.splice(index, 1);
    },
    addDocument() {
      if (!this.documents) {
        this.documents = [
          {
            document_name: "",
            description: "",
            file: null,
          },
        ];
      } else {
        this.documents.push({
          document_name: "",
          description: "",
          file: null,
        });
      }
      console.log(this.documents);
    },

    openWindow(link) {
      window.open(link);
    },
    documentSubmitted() {
      const self = this;

      const formData = new FormData(document.getElementById("document-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      if (self.$refs.pond && self.$refs.pond.getFiles()) {
        const files = self.$refs.pond.getFiles();
        const keys = Object.keys(self.$refs.pond.getFiles());
        //check when save and assign interview btn clicked
        if (keys.length == 0) {
          return;
        }
        for (let i = 0; i < keys.length; i += 1) {
          formData.append(`files[${i}]`, files[i].file);
        }
      }

      formData.append("userId", self.data_local.hashid);
      formData.append("document_name", self.document.document_name);
      formData.append("description", self.document.description);
      if (self.document.hash) {
        formData.append("hash", self.document.hash);
      }
      if (self.document.file) {
        formData.append("file", self.document.file);
      }
      let data = {};
      data.url = "upload-document";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          $("#document-cancel").click();
          $("#document-form").trigger("reset");
          this.documents = res.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Document Upload",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Document Upload',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Document UploadFailed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Document UploadFailed',
                icon: 'BellIcon',
                variant: 'success',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        });
    },
    editDocument(k) {
      $("#add-document").click();
      this.document = this.documents[k];
    },
    removeProfileAvatar() {
      let data = {};
      data.url = "/user/update-profile";
      data.params = {
        "remove-avatar": true,
        userId: this.$route.params.id,
      };

      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Profile Image removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Image removed',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Profile Image removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Profile Image removing Failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    updateProfilePic() {
      const self = this;
      const formData = new FormData();

      formData.delete("avatar"); // data is the name of the original input file; import to delete

      if (this.selectedFile) {
        formData.append(`avatar`, this.selectedFile, "blobs.jpg");
      }

      formData.append("userId", self.data_local.hashid);

      self.$http
        .post("/user/update-profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == "200") {
            if (self.imageSrc) {
              self.data_local.avatar = self.imageSrc;
            }
            this.fileCleared();
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Profile Update success.",
            //     icon: "BellIcon",
            //     text: res.data.message,
            //     variant: "success",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update success',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Profile Update Failed!",
            //     icon: "BellIcon",
            //     text: res.data.message,
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update Failed!',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Profile Update Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Profile Update Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-faq.scss";

@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "~cropperjs/dist/cropper.css";

.filepond--credits {
  display: none;
}
.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

.filepond--credits {
  display: none;
}
.close-crop-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-crop-container {
  display: flex;
  justify-content: center;
  align-content: flex-end;
}
</style>

