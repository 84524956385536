<template>
  <div class="w-100">
    <b-input-group class="input-group-merge">
      <b-form-input
        :id="id"
        :name="name"
        v-model="content"
        :type="passwordFieldType"
        :class="className"
        placeholder="Password"
        autocomplete="off"
        trim
        @input="(val) => $emit('update:value', val)"
      />
      <b-input-group-append is-text>
        <feather-icon
          class="cursor-pointer"
          :icon="passwordToggleIcon"
          @click="togglePasswordVisibility"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BForm,
  },
  mixins: [togglePasswordVisibility],
  name: "PasswordInput",
  props: {
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    name: { type: String, required: false, default: "" },
  },
  data() {
    return {
      body: this.value,
      content: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  created() {
    this.content = this.value;
  },
  mounted() {},
  methods: {},
};
</script>
